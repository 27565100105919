footer .container {
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

.builder-component__text-editor p {
  margin: 0;
}

.builder-component__text-editor span {
  margin: 0;
}

.discount-price-color {
  color: #dc3545;
}

.btn {
  font-size: 14px;
}

.relative {
  position: relative;
}

.button-subnav {
  position: absolute;
  display: none;
  bottom: 0;
  transform: translateY(100%);
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;

  font-weight: 600;
  min-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.col-subnav {
  position: absolute;
  display: none;
  bottom: 0;
  transform: translateY(100%);
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;

  font-weight: 600;
  min-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.builder-component__button:hover .button-subnav {
  display: flex;
}

.builder-component__image:hover .button-subnav {
  display: flex;
}

.col:hover .col-subnav {
  display: flex;
}

.builder-component__content a {
  user-select: auto;
  -webkit-user-select: auto;
}

.builder-component_text-data a {
  user-select: auto;
  -webkit-user-select: auto;
}

p:empty::after {
  content: "\00A0";
}

.builder-component__button p {
  margin-bottom: 0;
}

.remove__backdrop {
  backdrop-filter: none !important;
}

.product-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-product-button {
  background-color: var(--shop-theme-color);
  border: 1px solid var(--shop-theme-color);
}

.single-product-button:hover {
  background-color: var(--shop-theme-color-hover);
  border: 1px solid var(--shop-theme-color-hover);
}

.btn:focus {
  background-color: var(--shop-theme-color-hover) !important;
  border-color: var(--shop-theme-color-hover) !important;
}

.btn:first-child:active {
  background-color: var(--shop-theme-color-hover) !important;
  border-color: var(--shop-theme-color-hover) !important;
}
