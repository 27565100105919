.select-element-type {
    &__container {
        z-index: 9999999999;
        padding: 5px;
        width: max-content;
        background-color: $sidebar-bg;
        color: #fff;
        font-size: 12px;
    }
    &__list {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 6px;
        width: calc(100% - 28px);
        &__item {
            cursor: pointer;
            text-wrap: nowrap;
            border-radius: 4px;
            text-align: center;
            padding: 2px 6px;
            border: 1px solid #fff;
            transition: background-color 0.3s;
            &:hover {
                background-color: $input-background;
            }
        }
    }
}