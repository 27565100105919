.header {
  background-color: $sidebar-sub-bg;
  color: #ffffff;
  height: $header-height;
  font-size: 15px;
  z-index: 2;
  .MuiSvgIcon-root {
    font-size: 22.5px;
  }
}
.navbar {
  padding: 0px;
}

.saving-btn:disabled {
  cursor: not-allowed;
  background-color: var(--shop-theme-color);
  border: none;
}

@media (max-width: 991.98px) {
  .website-title {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .publish-btn,
  .user-container {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .resolution-slider {
    display: none;
  }
}
.header .home-btn {
  padding: $sidebar-icon-paddings;
  cursor: pointer;
}
.header .home-btn.active {
  background: #444444;
}
.avatar-online {
  background-color: #4caf50;
  position: absolute;
  width: 10px;
  left: 20px;
  bottom: -1px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 100%;
}

.loader {
  float: right;
  top: 4px;
  position: relative;
  margin: 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader-circle {
  width: 1em;
  height: 1em;
  border-top: 0.125em solid rgba(163, 175, 183, 0.5);
  border-right: 0.125em solid rgba(163, 175, 183, 0.5);
  border-bottom: 0.125em solid rgba(163, 175, 183, 0.5);
  border-left: 0.125em solid #a3afb7;
  border-radius: 50%;
  -webkit-animation: loader-circle 1.1s infinite linear;
  -o-animation: loader-circle 1.1s infinite linear;
  animation: loader-circle 1.1s infinite linear;
}
@-webkit-keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-ellipsis {
  top: -0.625em;
  width: 0.625em;
  height: 0.625em;
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out both -0.16s;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out both -0.16s;
  animation: loader-ellipsis 1.8s infinite ease-in-out both -0.16s;
}
.loader-ellipsis:before,
.loader-ellipsis:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out both;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out both;
  animation: loader-ellipsis 1.8s infinite ease-in-out both;
}
.loader-ellipsis:before {
  left: -0.875em;
  -webkit-animation-delay: -0.32s;
  -o-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-ellipsis:after {
  left: 0.875em;
}

@-webkit-keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
    box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
  }
  40% {
    -webkit-box-shadow: 0 0.625em 0 0 #9e9e9e;
    box-shadow: 0 0.625em 0 0 #9e9e9e;
  }
}

@-o-keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
  }
  40% {
    box-shadow: 0 0.625em 0 0 #9e9e9e;
  }
}

@keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
    box-shadow: 0 0.625em 0 -0.325em #9e9e9e;
  }
  40% {
    -webkit-box-shadow: 0 0.625em 0 0 #9e9e9e;
    box-shadow: 0 0.625em 0 0 #9e9e9e;
  }
}

.responsive-option {
  cursor: pointer;
  padding: 5px;
}

.responsive-option-active {
  background: #444;
  border-radius: 3px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
}

#BuilderContent {
  container-type: inline-size;
  background-color: #fff;
  width: 100%;
}

#BuilderContent.phone {
  width: 576px;
}

#BuilderContent.tablet {
  width: 992px;
}

#BuilderContent.laptop {
  width: 100%;
}
